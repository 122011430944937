import type { ActionQueueMessage } from '@inwink/actionqueue';
import * as Notifications from '@@event/data/notifications';
import { getNotifications } from "@@event/data/notifications";
import { actionQueue } from '@@actionsqueue/index';
import { States } from '@@services/services';
import { userDataActions } from './data';

function tracking() {
    return import("@@services/tracking/actions");
}

interface IDismissNotificationMessage {
    eventId: string;
    notificationId?: string;
    notificationIds?: string[];
}

export function dismissNotification(
    dispatch,
    getState: () => States.IAppState,
    notificationIds: string[]
): Promise<ActionQueueMessage<IDismissNotificationMessage>> {
    tracking().then(
        (mod) => mod.tracker.trackAction(dispatch, getState, 'notification', 'dismiss')
    );
    const state = getState();
    const result = Notifications.getNotifications(state.event.eventid, state.user.currentUser.detail.id).then((notifsData) => {
        let res;
        notificationIds.forEach((notificationId) => {
            const notifData = notifsData.notifications.data.find((n) => n.id === notificationId);

            if (notifData) {
                notifData.dismissed = true;
                notifsData.notifications.update(notifData);
            }   
        });

        if (notificationIds.length === 1) {
            res = {
                type: "dismissnotification",
                payload: <IDismissNotificationMessage>{
                    eventId: state.event.eventid,
                    notificationId: notificationIds[0]
                }
            };
        } else {

            res = {
                type: "dismissnotifications",
                payload: <IDismissNotificationMessage>{
                    eventId: state.event.eventid,
                    notificationIds
                }
            };
        }

        state.user.currentUser.data.unreadNotifications = Notifications.getUnreadNotifications(notifsData.notifications.data);

        return notifsData.save().then(() => {
            userNotificationsActions.dataChanged()(dispatch);
            return res;
        });
    });
    result.then((msg) => { actionQueue.enqueue(msg, dispatch, getState); });
    return result;
}

export const userNotificationsActions = {
    dataChanged(userData?: States.IPersonDataStore) {
        return userDataActions.dataChanged(userData);
    },

    dismissNotifications: (notificationIds: string[]) => (dispatch, getState: () => States.IAppState) => {
        return dismissNotification(dispatch, getState, notificationIds);
    },

    dismissAll() {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            getNotifications(state.event.eventid, state.user.currentUser.detail.id).then((notifsdata) => {
                const notifs = notifsdata.notifications.data.filter((n) => !n.dismissed);
                dismissNotification(dispatch, getState, notifs.map((n) => n.id));
            });
        };
    },

    dismissBy(kind: string, id: string) {
        return (dispatch, getState: () => States.IAppState) => {
            const state = getState();
            getNotifications(state.event.eventid, state.user.currentUser.detail.id).then((notifsdata) => {
                const notifs = notifsdata.notifications.data
                    .filter((n) => !n.dismissed && n.relatedToKind === kind && n.relatedToId === id);

                if (notifs.length) {
                    // logger.debug("dismiss notifications for discussion thread");
                    dismissNotification(dispatch, getState, notifs.map((n) => n.id));
                } else {
                    // logger.debug("no notification to dismiss for discussion thread");
                }
            });
        };
    }
};
